<template lang="pug">
main
  .main-container
    .logo-container
      img(:src="require('@/assets/img/logo-solo.svg')",alt="Time Square Logo")
    .welcome-text WELCOME TO TIME SQUARE CLUB, RESORT & SPA
    h1 Terms & Conditions
  .container <b>Acceptance of Terms:</b><br/>By accessing or using the website time square club, you agree to be bound by these terms and conditions. If you do not agree with any of these terms, you are prohibited from using or accessing this site.
    <br/>
    <br/><b>Content Ownership:</b><br/>All content included on this site, such as text, graphics, logos, images, and data, is the property of time square club or its content suppliers and is protected by international copyright laws.
    <br/>
    <br/><b>Use of Content:</b><br/>The use of any content on this site for commercial purposes without prior written permission from time square club is strictly prohibited.
    <br/>
    <br/><b>Limitation of Liability:</b><br/>Time square club will not be liable for any damages or losses arising from the use of this site or any content contained on it.
    <br/>
    <br/><b>Changes to Terms:</b><br/>Time square club reserves the right to make changes to these terms and conditions at any time without notice. Your continued use of the site following any changes indicates your acceptance of the new terms.
    <br/>
    <br/><b>User Conduct:</b><br/>You agree to use this site only for lawful purposes and in a manner that does not infringe the rights of, or restrict or inhibit the use and enjoyment of, this site by any third party.
    <br/>
    <br/><b>Privacy Policy:</b><br/>Time square club takes the privacy of its users seriously and has a detailed privacy policy in place. Please review it before using this site.
    <br/>
    <br/><b>Governing Law:</b><br/>These terms and conditions are governed by and construed in accordance with the laws of India.
    <br/>
    <br/><b>Dispute Resolution:</b><br/>Any dispute arising from or related to these terms and conditions or use of this site will be resolved through arbitration in accordance with the laws of India.
    <br/>
    <br/><b>Entire Agreement:</b><br/>These terms and conditions constitute the entire agreement between you and time square club and supersede all prior agreements, understandings, and representations.

</template>

<script>

import DisplayHeader from '../components/DisplayHeader.vue'

export default {
    name: "TermsConditions",
  components: {
	DisplayHeader
  },
  mounted() {
        window.scroll(0,0);
    }
}
</script>

<style scoped>
.main-container {
	color: black;
	background-color: #d7d5d0;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 60px 20px;
  margin-bottom: 80px;
}

.logo-container img {
	width: 3rem;
	margin: 1rem;
}

.welcome-text {
	margin: 1rem;
	margin-top: 0;
	font-family: Montserrat, sans-serif;
	font-size: 1rem;
	opacity: 0.5;
	font-weight: 400;
}

h1 {
	margin: 0;
	width: 80%;
	font-family: "DM Serif Display",serif;
	font-size: 3rem;
	text-align: center;
	font-weight: 400;
}


</style>